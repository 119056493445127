import React from "react";
// import img from "./Market-1024x342.jpg";
import {
    Box,
    Heading,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    List,
    ListItem,
    ListIcon,
    OrderedList,
    UnorderedList
} from '@chakra-ui/react';
import style from "./homeloan.module.css";
import HomeLoanCalculator from "../../components/HomeLoanClaculator/Homeloan";

const Homeloan = () => {

    // scrole to top 

    return (
        <Box>
        <Box w={"90%"} margin={"30px auto"} display={"flex"} gap={10}>
            <Box width={"100%"} textAlign={"left"} flex={7} margin={"10px auto"}>
                {/* <img src={img} alt="property_img" /> */}
                <Heading className={style.headings} m={"20px 0"} as='h3' size='lg'> HOME LOANS </Heading>
                <Text textAlign={"justify"} fontSize={"md"} m={"10px 0"} >
                    Owning a home stands as a pinnacle achievement for many individuals, marking a significant milestone in their lives. However, this achievement comes with a hefty price tag. Fulfilling this dream entails diligent effort on the part of the buyer, often necessitating the acquisition of a Home Loan.
                </Text>
                <Text textAlign={"justify"} fontSize={"md"} m={"10px 0"}>
                    A home loan, also known as a housing loan, constitutes a sum of money borrowed by an individual, typically from banks or other financial institutions. Repayment terms vary, requiring borrowers to settle the loan amount along with interest over a period spanning from 10 to 30 years, facilitated through Easy Monthly Installments (EMIs).
                </Text>
                <Text textAlign={"justify"} fontSize={"md"} m={"10px 0"}>
                    Highlighted below are discussions on the top 10 home loans and their corresponding interest rates in the Indian market for the year 2024. This overview aims to aid individuals in their home-buying endeavors, assisting them in selecting the most suitable home loan option available in India.
                </Text>
                {/* part1 */}
                <Heading className={style.headings} m={0} marginTop={20} as='h3' size='lg'>Top Home Loan Banks</Heading>
                <Text textAlign={"justify"} fontSize={"md"} m={"10px 0"}>
                    Here are some of the top home loan options available in India for 2024, including their associated interest rates and processing fees:
                </Text>
                <TableContainer id={style.table}>
                    <Table variant='striped'>
                        <TableCaption>Best Home Loan Providers in India 2024</TableCaption>
                        <Thead>
                            <Tr>
                                <Th>S.No.</Th>
                                <Th>Bank Name</Th>
                                <Th>Interest Rate</Th>
                                <Th>Processing Fees</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td>1</Td>
                                <Td>SBI Home Loan</Td>
                                <Td>8.05%-8.55%</Td>
                                <Td>0.35% of the loan amount and a maximum of INR 10,000 + GST</Td>
                            </Tr>
                            <Tr>
                                <Td>2</Td>
                                <Td>HDFC Home Loan</Td>
                                <Td>8.60%-9.50%</Td>
                                <Td>Up to 0.50% of the loan amount or INR 3,000, whichever is higher, plus applicable taxes</Td>
                            </Tr>
                            <Tr>
                                <Td>3</Td>
                                <Td>Axis Bank Home Loan</Td>
                                <Td>7.60% - 8.05%</Td>
                                <Td>Up to 1% of the Loan amount subject to a minimum of INR 10,000 Upfront processing fee of INR 2,500 + GST</Td>
                            </Tr>
                            <Tr>
                                <Td>4</Td>
                                <Td>ICICI Home Loan</Td>
                                <Td>8.40% - 9.45%</Td>
                                <Td>Up to 1.00% of the loan amount + GST</Td>
                            </Tr>
                            <Tr>
                                <Td>5</Td>
                                <Td>Bank of Baroda Home Loan</Td>
                                <Td>7.45% - 8.80%</Td>
                                <Td>Up to 0.50% of the loan amount or a maximum of INR 7,500 + GST</Td>
                            </Tr>
                            <Tr>
                                <Td>6</Td>
                                <Td>PNB Home Loan</Td>
                                <Td>8.25% - 11.20%</Td>
                                <Td>0.35% of the loan amount, subject to a minimum and maximum of INR 2,500 and INR 15,000, respectively</Td>
                            </Tr>
                            <Tr>
                                <Td>7</Td>
                                <Td>LIC Housing Finance Home Loan</Td>
                                <Td>8.00% - 9.25%</Td>
                                <Td>Up to 1.0% of the loan amount + applicable GST</Td>
                            </Tr>
                            <Tr>
                                <Td>8</Td>
                                <Td>Aditya Birla Home Loan</Td>
                                <Td>8.00% - 13.00%</Td>
                                <Td>Up to 1.0% of the loan amount + applicable GST</Td>
                            </Tr>
                            <Tr>
                                <Td>9</Td>
                                <Td>Citibank Home Loan</Td>
                                <Td>6.50% - 7.40%</Td>
                                <Td>Up to 0.40% of the loan amount + GST</Td>
                            </Tr>
                            <Tr>
                                <Td>10</Td>
                                <Td>Bajaj Housing Finance Home Loan</Td>
                                <Td>7.70% - 14.00%</Td>
                                <Td>Up to 1.0% of the loan amount + applicable GST</Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
                {/* part 2 */}
                <Heading className={style.headings} m={0} marginTop={20} as='h3' size='lg'>Factors to Consider While Applying for a Home Loan</Heading>
                <Text textAlign={"justify"} fontSize={"md"} m={"10px 0"}>
                    Consider these essential factors when applying for a Home Loan:
                </Text>
                <OrderedList>
                    <ListItem>Total EMI Amount:</ListItem>
                    <Text textAlign={"justify"} fontSize={"md"} m={"4px 0 20px 30px"}>
                        Monthly instalments cover both principal and interest. Over time, principal repayment increases while interest payments decrease.
                    </Text>
                    <ListItem>Duration:</ListItem>
                    <Text textAlign={"justify"} fontSize={"md"} m={"4px 0 20px 30px"}>
                        Assess the repayment period based on your expected income fluctuations.
                    </Text>
                    <ListItem>Rate of Interest:</ListItem>
                    <Text textAlign={"justify"} fontSize={"md"} m={"4px 0 20px 30px"}>
                        Interest charges are determined by the principal amount borrowed.
                    </Text>
                    <ListItem>Initial Payment or Down Payment:</ListItem>
                    <Text textAlign={"justify"} fontSize={"md"} m={"4px 0 20px 30px"}>
                        Contribute a down payment to reduce the loan amount and subsequent interest payments. While some institutions offer loans up to 100% of the property value, a down payment of 10% to 20% is advisable to manage repayments effectively.
                    </Text>
                    <ListItem>Mortgage Insurance:</ListItem>
                    <Text textAlign={"justify"} fontSize={"md"} m={"4px 0 20px 30px"}>
                        Protect your family's financial well-being with home loan insurance, ensuring loan repayment in the event of unforeseen circumstances.
                    </Text>
                    <ListItem>Additional Fees:</ListItem>
                    <Text textAlign={"justify"} fontSize={"md"} m={"4px 0 20px 30px"}>
                        Account for administrative, processing, or service fees associated with the loan application. Clarify whether these are recurring or one-time charges and adjust your budget accordingly.
                    </Text>
                </OrderedList>
                {/* part 3 */}
                <Heading className={style.headings} m={0} marginTop={20} as='h3' size='lg'>Best Bank for Home Loan in India: Overview</Heading>
                <Heading className={style.headings} m={0} marginTop={5} marginBottom={0} as='h4' size='md'>1) SBI Home Loan</Heading>
                <Text textAlign={"justify"} fontSize={"md"} m={"10px 0"}>
                    SBI Home Loan stands out as one of the top choices for home financing in India. With competitive interest rates starting at 8.55% p.a., borrowers can enjoy loan terms of up to 30 years. The processing fee is set at 0.35% of the loan amount (minimum Rs. 2,000; maximum Rs. 10,000), plus applicable taxes. Female borrowers are eligible for an additional interest reduction of 0.05%, making SBI Home Loans a preferred option among many.
                </Text>
                <Heading className={style.headings} m={0} marginTop={5} marginBottom={0} as='h4' size='md'>2) HDFC Home Loan</Heading>
                <Text textAlign={"justify"} fontSize={"md"} m={"10px 0"}>
                    HDFC Home Loans offer affordable financing solutions with interest rates starting at 8.60% per annum. Loan durations of up to 30 years are available, with processing fees capped at Rs. 3,000 (plus applicable taxes) for employed individuals. Unemployed individuals are subject to different processing fees.
                </Text>
                <Heading className={style.headings} m={0} marginTop={5} marginBottom={0} as='h4' size='md'>3) Axis Bank Home Loan</Heading>
                <Text textAlign={"justify"} fontSize={"md"} m={"10px 0"}>
                    Axis Bank Home Loan is renowned for its competitive interest rates, starting at 6.90% p.a. for floating-rate loans and 12% p.a. for fixed-rate loans. Borrowers can extend the tenure up to 30 years for floating-rate loans and up to 20 years for fixed-rate loans. The processing fee can be up to 1% of the loan amount, with a minimum of Rs. 10,000, of which Rs. 2,500 plus GST is payable upfront.
                </Text>
                <Heading className={style.headings} m={0} marginTop={5} marginBottom={0} as='h4' size='md'>4) ICICI Home Loan</Heading>
                <Text textAlign={"justify"} fontSize={"md"} m={"10px 0"}>
                    ICICI Bank offers housing loans with competitive interest rates starting at 8.60% p.a. Borrowers can opt for loan terms of up to 30 years, with no processing fees for floating-rate loans. Processing fees for other loan types range from 0% to 0.50% of the loan amount, plus applicable taxes. Additionally, ICICI Bank collaborates with the PMAY Scheme, providing further benefits to homebuyers.
                </Text>
                <Heading className={style.headings} m={0} marginTop={5} marginBottom={0} as='h4' size='md'>5) Bank of Baroda Home Loan</Heading>
                <Text textAlign={"justify"} fontSize={"md"} m={"10px 0"}>
                    Bank of Baroda Home Loans feature attractive interest rates starting at 7.95% p.a., with loan tenures extending up to 30 years. The bank offers loans up to Rs. 10 crores, with processing fees as low as 0.25% of the loan amount. Home loans with floating interest rates do not incur prepayment penalties and can be topped up multiple times during the loan term.
                </Text>
                <Heading className={style.headings} m={0} marginTop={5} marginBottom={0} as='h4' size='md'>7) LIC Housing Finance Home Loan</Heading>
                <Text textAlign={"justify"} fontSize={"md"} m={"10px 0"}>
                    LIC Housing Finance Limited provides affordable home loan options ranging from Rs. 1 lakh to Rs. 15 crores, with flexible terms of up to 30 years. Interest rates start at 6.90% p.a., with processing fees up to 0.5% of the loan amount plus GST. Special programs cater to NRIs and retirees at competitive rates.
                </Text>
                <Heading className={style.headings} m={0} marginTop={5} marginBottom={0} as='h4' size='md'>8) Aditya Birla Home Loan</Heading>
                <Text textAlign={"justify"} fontSize={"md"} m={"10px 0"}>
                    Aditya Birla Housing Finance offers home loans with processing fees as low as 1% of the loan amount and interest rates ranging from 9.00% to 12.50% p.a. Borrowers can avail loan amounts varying from 75% to 90% of the property value, with no prepayment penalties for floating-rate loans and repayment terms of up to 30 years.
                </Text>
                <Heading className={style.headings} m={0} marginTop={5} marginBottom={0} as='h4' size='md'>9) Citibank Home Loan</Heading>
                <Text textAlign={"justify"} fontSize={"md"} m={"10px 0"}>
                    Citibank provides low-interest home loans starting at 6.50% p.a., with repayment terms extendable up to 25 years. Financing up to 80% of the property cost is available, making it a viable option for many homebuyers.
                </Text>
                <Heading className={style.headings} m={0} marginTop={5} marginBottom={0} as='h4' size='md'>10) Bajaj Housing Finance Home Loan</Heading>
                <Text textAlign={"justify"} fontSize={"md"} m={"10px 0"}>
                    Bajaj Housing Finance offers attractive home loan interest rates ranging from 7.70% to 14.00% p.a., with processing fees up to 1.0% of the loan amount plus applicable GST. With its popularity and competitive offerings, it remains a prominent choice among home loan providers in India.
                </Text>
            </Box>
            
        </Box >
      
            <HomeLoanCalculator/>
        </Box>
    );
};

export default Homeloan;
