import React from 'react'
import { Heading, Text , Box} from '@chakra-ui/react'

const FlyAsh = () => {
    return (
        <div style={{width:"80vw", margin:"40px auto"}}>
            <Heading as="h2" size="lg" mb={4} color="teal.700">
                Fly Ash
            </Heading>
            <Text fontSize="lg" color="gray.600" marginBottom= "10px">
                Fly ash is a fine powder which is a by product from burning pulverized coal in electric generation power plants. The fly ash produced by coal-fired power plants provide an excellent prime material used in blended cement, mosaic tiles, and hollow blocks among others. Fly ash can be used as prime material in blocks, paving or bricks; however, one the most important applications is PCC pavement. PCC pavements use a large amount of concrete and substituting fly ash provides significant economic benefits.
            </Text>
            <hr />
            <Text>
                <Box margin="10px 0">
                <b>Types:  </b> Fly ash
                <b> Brands:</b>  Dirk | Pozzocrete | Tata | JSW | Ashtech
                </Box>
          
            </Text>
        </div>
    )
}

export default FlyAsh