

export const DATA_FETCH_LOADING = "data/fetch/loading";

export const DATA_FETCH_ERROR = "data/fetch/error"; 

export const DATA_FETCH_WISHLIST = "data/wishlist/successfull"; 
  
export const DATA_UPDATED_WISHLIST = "data/updated/successfull"; 
// data fetch successfull :  
export const DATA_COMMERCIAL_RENT_DATA = "data/commercial/rent"; 

export const DATA_COMMERCIAL_BUY_DATA = "data/commercial/buy"; 

export const DATA_RESIDENTIAL_RENT_DATA = "data/residential/rent";  

export const DATA_RESIDENTIAL_BUY_DATA = "data/residential/buy";  


