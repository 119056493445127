import { Box, Heading, Text, Image, Divider, VStack } from '@chakra-ui/react';
import React from 'react';

const Rock = () => {
    return (
        <VStack spacing={8} align="start" p={6} maxW="800px" mx="auto">
            {/* Image Section */}
            <Box w="full">
                <Image 
                    src="./boulders.jpg" 
                    alt="Rock Boulders" 
                    borderRadius="lg" 
                    boxShadow="lg" 
                    maxH="400px" 
                    objectFit="cover" 
                    mx="auto"
                />
            </Box>

            {/* Title */}
            <Box>
                <Heading as="h1" size="xl" color="teal.700" mb={4}>
                    Rock Boulders 1000kgs
                </Heading>
            </Box>

            {/* Description */}
            <Box>
                <Text fontSize="lg" color="gray.600" mb={4}>
                    1 metric ton = 1 ton jumbo bag packed in 45-48 kgs PP bag, HDPE bag (if required, used fine quality bag, jumbo bag 1 metric tonne), and also loose packing options.
                </Text>

                <Divider borderColor="gray.300" />

                {/* Packing Information */}
                <Heading as="h2" size="md" color="teal.700" mt={4} mb={2}>
                    Packing Details
                </Heading>
                <Text fontSize="lg" color="gray.600">
                    We offer rock boulders in the following packing options:
                </Text>
                <Text fontSize="lg" color="gray.600" mt={2}>
                    - 1 metric ton = 1000 kgs, packed in 21 to 22 bags
                    <br />
                    - 1 metric ton = 1 ton jumbo bag
                    <br />
                    - Packed in 45-48 kgs PP bag, HDPE bag (if required, used fine quality bag, jumbo bag 1 metric tonne), and also loose packing.
                </Text>
            </Box>
        </VStack>
    );
};

export default Rock;
