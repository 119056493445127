import React from 'react'
import { Link } from 'react-router-dom';
import logo from "./logo.png"
import { Image } from '@chakra-ui/react';

const FooterLogo = () => {
  return (
    <Link to={"/"}>
      <Image width={"160px"} src={logo} alt="logo" />
    </Link>
  )
}

export default FooterLogo