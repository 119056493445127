import React from "react";
import {
    Tabs,
    TabList,
    TabPanels,
    TabPanel,
    Tab,
    Box,
    Image,
    Text,
    Table,
    Tbody,
    Th,
    Thead,
    Tr,
    Td,
    List,
    ListItem,
    Heading,
    useDisclosure
} from "@chakra-ui/react";

const IronStell = () => {
    return (
        <Box p={5} bg="#f4f4f4">
            <Text fontSize="xl" mb={4}>
                Iron, Steel & Copper Items
            </Text>

            <Tabs variant="enclosed">
                <TabList mb={4} borderBottom="2px solid #4CAF50">
                    <Tab>Deform Steel Bars</Tab>
                    <Tab>GI Pipes</Tab>
                    <Tab>Hollow Sections</Tab>
                    <Tab>MS Angles</Tab>
                    <Tab>Channels</Tab>
                    <Tab>MS Flat Bars</Tab>
                    <Tab>Flat Bars</Tab>
                    <Tab>Fasteners</Tab>
                    <Tab>Stainless Steel</Tab>
                    <Tab>Copper Items</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <Box borderWidth={2} p={4} mb={4} bg="white">
                            <Image src="steel.jpg" boxSize="700px" mb={4} />
                            <Text fontSize="lg" fontWeight="bold">
                                Deform Steel Bars
                            </Text>
                            <Text mb={2}>
                                Leveraging on our vast industry knowledge and in-depth
                                technological expertise of manufacturing, supplying, and exporting
                                a wide gamut of Structural Steel products, we are pleased to offer
                                Deform Steel Bars. These bars are available with us in different
                                specifications and dimensions. It is manufactured using cutting-edge
                                technologies from the finest grades of alloy steel that are
                                procured from trustworthy vendors of the domain.
                            </Text>
                            <Text fontWeight="bold">Features:</Text>
                            <List spacing={2}>
                                <ListItem>Sturdy construction</ListItem>
                                <ListItem>Flawless finish</ListItem>
                                <ListItem>High compressive strength</ListItem>
                            </List>
                            <Text fontWeight="bold">Specifications:</Text>
                            <List spacing={2}>
                                <ListItem>Diameter: 16-25 mm</ListItem>
                                <ListItem>Length: 6-12 M</ListItem>
                            </List>
                        </Box>
                    </TabPanel>

                    <TabPanel>
                        <Box borderWidth={2} p={4} mb={4} bg="white">
                            <Image src="Gi pipes.jpg" boxSize="700px" mb={4} />
                            <Text fontSize="lg" fontWeight="bold">
                                GI Pipes
                            </Text>
                            <Text mb={2}>
                                Product Description: Tata Pipes had been a pioneer in the
                                manufacturing of pipes, specifically meant for plumbing
                                applications. To meet the continually increasing demand for
                                quality pipes in the plumbing and irrigation segment, significant
                                improvements in the quality of pipes have been brought about
                                through innovation in the pipe-making and galvanizing process.
                                Galvanized pipes are one of the most important elements in a
                                plumbing setup. The pipes need to satisfy specific characteristics
                                during their intended usage, particularly with respect to ease of
                                installation and water flow, long-term behavior with respect to
                                corrosion resistance, and longevity.
                            </Text>
                            <Text fontWeight="bold">Features:</Text>
                            <List spacing={2}>
                                <ListItem>Shape: Round, Square</ListItem>
                                <ListItem>Thickness (mm): 1/4-4</ListItem>
                                <ListItem>Brand: TATA GI Pipes</ListItem>
                                <ListItem>Length: 6m</ListItem>
                                <ListItem>Manufacturer: Tata Steel</ListItem>
                                <ListItem>Length: 6-12 M</ListItem>
                            </List>
                        </Box>
                    </TabPanel>

                    <TabPanel>
                        <Box borderWidth={2} p={4} mb={4} bg="white">
                            <Image src="hollow.jpg" boxSize="700px" mb={4} />
                            <Text fontSize="lg" fontWeight="bold">
                                Hollow Sections
                            </Text>
                            <Text mb={2}>We are offering Hollow Sections to our client.</Text>
                            <Text fontWeight="bold">Usage:</Text>
                            <Text mb={2}>Industrial</Text>
                            <Text fontWeight="bold">Minimum Order Quantity:</Text>
                            <Text mb={2}>1000 Kilogram</Text>
                            <Text fontWeight="bold">Specifications:</Text>
                            <List spacing={2}>
                                <ListItem>Dimension: 20 x 20 to 400 x 400</ListItem>
                                <ListItem>Thickness: 1mm to 25mm</ListItem>
                                <ListItem>Length: 6000mm to 12000mm</ListItem>
                                <ListItem>Grade Available is ASTM A 500, IS 4923</ListItem>
                            </List>
                        </Box>
                    </TabPanel>

                    <TabPanel>
                        <Box borderWidth={2} p={4} mb={4} bg="white">
                            <Image src="msANGLE.jpg" boxSize="700px" mb={4} />
                            <Text fontSize="lg" fontWeight="bold">
                                MS Angles
                            </Text>
                            <Text mb={2}>
                                Backed with years of metal industry experience, our company supplies
                                a broad range of supreme quality MS Angle to our clients. Available
                                in various lengths and weights, these steel angles are very durable
                                and functional. Ideal for use in building construction works, bridges
                                construction, industrial structures, transmission towers, and ships,
                                these angles are offered by us at highly affordable prices.
                            </Text>
                            <Text fontWeight="bold">Specification:</Text>
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>Size</Th>
                                        <Th>Weight in Kgs. per Feet</Th>
                                        <Th>Weight in Kgs. per Mtr</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td>Small</Td>
                                        <Td>0.5</Td>
                                        <Td>1.64</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Medium</Td>
                                        <Td>1.0</Td>
                                        <Td>3.28</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Large</Td>
                                        <Td>1.5</Td>
                                        <Td>4.92</Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </Box>
                    </TabPanel>

                    <TabPanel>
                        <Box borderWidth={2} p={4} mb={4} bg="white">
                            <Image src="mschannel.jpg" boxSize="700px" mb={4} />
                            <Text fontSize="lg" fontWeight="bold">
                                Channels
                            </Text>
                            <Text mb={2}>
                                We supply a broad range of MS Channel that is used in metal docking,
                                space frame towers, prefabricated shelters, and pre-engineered building
                                systems. Offered channel is precisely manufactured by our diligent
                                professionals by utilizing high-grade mild steel at our well-equipped
                                manufacturing unit. Furthermore, this MS Channel can be availed in
                                different specifications by our customers.
                            </Text>
                            <Text fontWeight="bold">Key Features:</Text>
                            <List spacing={2}>
                                <ListItem>Robust construction</ListItem>
                                <ListItem>High ductility</ListItem>
                                <ListItem>Durability</ListItem>
                                <ListItem>Resistant to corrosion</ListItem>
                            </List>
                        </Box>
                    </TabPanel>

                    <TabPanel>
                        <Box borderWidth={2} p={4} mb={4} bg="white">
                            <Image src="msFlat-Bars.jpg" boxSize="700px" mb={4} />
                            <Text fontSize="lg" fontWeight="bold">
                                MS Flat Bars
                            </Text>
                            <Text mb={2}>
                                Our sound reputation as one of the trusted manufacturers and suppliers
                                of a wide array of Structural Steel products has empowered us to present
                                Flat Bars. These flat bars are offered by us in varied thickness and
                                length specifications. Our offered bars are widely used for diverse
                                applications such as construction, automobile, and shipbuilding. These
                                are widely appreciated for their robustness and corrosion resistance
                                features.
                            </Text>
                            <Text fontWeight="bold">Specifications:</Text>
                            <List spacing={2}>
                                <ListItem>Width: 25 mm to 200 mm</ListItem>
                                <ListItem>Thickness: 5 mm to 40 mm</ListItem>
                                <ListItem>Length: 6 M to 12 M</ListItem>
                            </List>
                        </Box>
                    </TabPanel>

                    <TabPanel>
                        <Box borderWidth={2} p={4} mb={4} bg="white">

                            <Text fontSize="lg" fontWeight="bold">
                                Flat Bars
                            </Text>
                            <Text mb={2}>
                                Our sound reputation as one of the trusted manufacturers and
                                suppliers of a wide array of Structural Steel products has
                                empowered us to present Flat Bars that are manufactured using
                                premium grades of metal alloys. These bars are available in
                                different thickness and length specifications to match
                                requirements of applications. We stringently follow prescribed
                                norms of quality in accordance with industry norms and present
                                these at leading industry rates.
                            </Text>
                            <Text fontWeight="bold">Features</Text>
                            <List spacing={2}>
                                <li>High structural strength</li>
                                <li>Long service life</li>
                                <li>Wide ranging applications</li>
                            </List>
                            <Text fontWeight="bold">Specifications:</Text>
                            <List spacing={2}>
                                <li>Thickness: 10 - 35 mm</li>
                                <li>Length: 6-12 M</li>
                            </List>
                        </Box>
                    </TabPanel>
                    <TabPanel>
                        <Box borderWidth={2} p={4} mb={4} bg="white">
                            <Image src="fastners.jpg" boxSize="700px" mb={4} />
                            <Text fontSize="lg" fontWeight="bold">
                                Fasteners
                            </Text>
                            <Text mb={2}>
                                Owing to our state-of-the-art infrastructural base, we are engaged
                                in providing wide range of Hardware Fasteners.
                            </Text>
                            <Text fontWeight="bold">Features:</Text>
                            <List spacing={2}>
                                <ListItem>Sturdy construction</ListItem>
                                <ListItem>Fine finish</ListItem>
                                <ListItem>Elevated durability </ListItem>
                            </List>
                            <Text fontWeight="bold">Specifications</Text>
                            <List spacing={2}>
                                <ListItem>Widely used for fastening purpose</ListItem>
                                <ListItem>Material: Metal alloys</ListItem>
                                <ListItem>Available in several sizes</ListItem>
                            </List>
                            <List spacing={2}>
                                <ul>
                                    <li>Screws</li>
                                    <li>Self Driving Screws</li>
                                    <li>Self tampering Screws</li>
                                    <li>Machine Screws</li>
                                    <li>Bolts</li>
                                    <li>Nuts/Inserts</li>
                                    <li>Pod/Rivets/Others</li>
                                    <li>Washers</li>
                                    <li>Anchor Fasteners/Shield Anchor</li>
                                </ul>
                            </List>
                        </Box>
                    </TabPanel>

                    <TabPanel>
                        <Box borderWidth={2} p={4} mb={4} bg="white">
                            <Image src="stainlessSteel.jpg" boxSize="700px" mb={4} />
                            <Text fontSize="lg" fontWeight="bold">
                                Stainless Steel
                            </Text>
                            <Text mb={2}>
                                We are engaged in manufacturing and supplying a comprehensive range of
                                Stainless Steel products. The stainless steel offered by us is known for
                                its resistance to corrosion, durability, and attractive finish. This range
                                of stainless steel products finds extensive application in various industries,
                                including construction, automotive, and aerospace.
                            </Text>
                            <Text fontWeight="bold">Types:</Text>
                            <List spacing={2}>
                                <ListItem>Stainless Steel Pipes</ListItem>
                                <ListItem>Stainless Steel Plates</ListItem>
                                <ListItem>Stainless Steel Sheets</ListItem>
                            </List>
                            <Text fontWeight="bold">Grades Available:</Text>
                            <List spacing={2}>
                                <ListItem>304</ListItem>
                                <ListItem>316</ListItem>
                            </List>
                        </Box>
                    </TabPanel>

                    <TabPanel>
                        <Box borderWidth={2} p={4} mb={4} bg="white">
                            <Image src="copper items.jpg" boxSize="700px" mb={4} />
                            <Text fontSize="lg" fontWeight="bold">
                                Copper Items
                            </Text>
                            <Text mb={2}>
                                Our company is a renowned supplier of Copper Items that includes a wide
                                range of copper products suitable for various industrial applications.
                                The copper items are made from high-grade copper and are known for their
                                excellent electrical conductivity, corrosion resistance, and durability.
                            </Text>
                            <Text fontWeight="bold">Types:</Text>
                            <List spacing={2}>
                                <ListItem>Copper Sheets</ListItem>
                                <ListItem>Copper Pipes</ListItem>
                                <ListItem>Copper Bars</ListItem>
                            </List>
                            <Text fontWeight="bold">Grades Available:</Text>
                            <List spacing={2}>
                                <ListItem>C11000</ListItem>
                                <ListItem>C12200</ListItem>
                            </List>
                        </Box>
                    </TabPanel>
                </TabPanels>
            </Tabs>
            <Box>
                <Heading style={{fontSize:"22px"}}> <b> Iron, Steel & Copper Items:</b><br /> Deform Steel Bars, GI Pipes, Hollow Sections, MS Angles, Channels, MS Flat Bars,
                    Flat Bars, Fasteners, Stainless Steel, Copper Items</Heading>
                    <h1 style={{fontSize:"18px" , fontWeight:"700"}}>STEEL  SEMIS</h1>
                    <img style={{margin:"20px 0"}} src="./billets.webp" alt="" />
                    <h1 style={{fontSize:"22px" , fontWeight:"700"}}>STEEL  LONG PRODUCTS</h1>
                    <img style={{margin:"20px 0"}} src="./steellong-products.webp" alt="steellong-products" />
                    <img style={{margin:"20px 0"}} src="./beams.webp" alt="steellong-products" />
                    <img style={{margin:"20px 0"}} src="./wire-rods.webp" alt="steellong-products" />
                    <img style={{margin:"20px 0"}} src="./steel-pipes.webp" alt="steellong-products" />
                    <img style={{margin:"20px 0"}} src="./angles.webp" alt="steellong-products" />
                    <img style={{margin:"20px 0"}} src="./channels.webp" alt="steellong-products" />
                    <img style={{margin:"20px 0"}} src="./hollow-sections.webp" alt="steellong-products" />
                    <img style={{margin:"20px 0"}} src="./hot-rolled-coils.webp" alt="steellong-products" />
                    <img style={{margin:"20px 0"}} src="./hot-rolled-sheets.webp" alt="steellong-products" />
            </Box>
        </Box>
    );
};

export default IronStell;
