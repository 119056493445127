// src/AvatarComponent.js
import React from 'react';

const AvatarComponent = ({ name, url }) => {
  // Function to get initials from the name
  const getInitials = (name) => {
    const nameArray = name.split(' ');
    if (nameArray.length === 1) return nameArray[0].charAt(0).toUpperCase();
    return `${nameArray[0].charAt(0)}${nameArray[1].charAt(0)}`.toUpperCase();
  };

  const avatarStyle = {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '20px',
    backgroundColor: url ? 'transparent' : 'gray',
    color: url ? 'transparent' : 'white',
    backgroundImage: url ? `url(${url})` : 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const badgeStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <div style={avatarStyle}>
      {!url && (
        <div style={badgeStyle}>
          {getInitials(name)}
        </div>
      )}
    </div>
  );
};

export default AvatarComponent;
