
export const COUNTRY_VAL = "country/change";  

export const RECENTLY_VISITED = "recentyl/visited";  

export const LOOKINGFOR = "looking/for"; 

export const NOTIFICATION = "notification/count"; 



